import React, {Component} from "react"

import { 
  Button,
  Window,
  WindowContent,
  WindowHeader,
  Table,
  TableHead,
  TableRow,
  TableDataCell,
  TableHeadCell,
  TableBody
} from 'react95'

import WindowSite from './WindowSite'

import Draggable from 'react-draggable';

const portfolio = [
  {
    id: 1,
    title: 'BOTS Interreg Atlantic Area',
    logo: require('./assets/bots-logo.png'),
    image: require('./assets/bots-web.jpg'),
    url: 'https://www.botsproject.eu',
    brief: 'Projet européen de recherche en médecine personnalisée',
    need: 'Le projet BOTS (Blue Organoid for Treatment Selection) est un projet européen financé par le programme européen Interreg Atlantic Area. Il réunit des équipes de recherche de cinq universités à travers quatre pays pour développer des technologies de médecine personnalisée. BOTS avait besoin d\'un logo et d\'un site web pour communiquer sur ses avancées.',
    solution: 'OnlineCreation a étudié le contexte dans lequel s\'inscrit le projet BOTS pour concevoir un logo qui évoque la biologie et l\'océan, puis un site web quadrilingue (français, anglais, espagnol, portugais) pour présenter le projet, ses avancées et ses partenaires. Le site est conçu avec WordPress pour être facilement mis à jour par les équipes du projet.',
    technologies: ['wordpress', 'php', 'graphicdesign', 'html5', 'js'],
    year: 2024,
    category: 'site',
  },
  {
    id: 2,
    title: 'LM Parts',
    logo: require('./assets/lmparts-logo.png'),
    image: require('./assets/lmparts-web.jpg'),
    url: 'https://www.lmparts.fr',
    brief: 'Fabricant de coques de clefs de voitures Renault et Dacia',
    need: 'LM Parts démarrait son activité de constructeur d\'accessoires automobiles 100% made in France. Pour commencer à vendre sur Internet sans trop atteindre son budget initial, l\'entreprise naissante avait besoin d\'une solution française et bon marché.',
    solution: 'OnlineCreation a proposé une solution clé en main sous la forme d\'un site de e-commerce en une page, simple et esthétique, facile à utiliser et maintenir grâce à OnlineCreation.me.',
    technologies: ['js', 'html5', 'bootstrap', 'onlc', 'css3', 'graphicdesign'],
    year: 2024,
    category: 'site',
  },
  {
    id: 3,
    title: 'Société des Neurosciences',
    logo: require('./assets/neurosciences-logo.png'),
    image: require('./assets/neurosciences-web.jpg'),
    url: 'https://www.neurosciences.asso.fr',
    brief: 'Portail web d\'association de chercheurs',
    need: 'Refondre un site existant avec des technologies modernes, tout en conservant une connexion avec une base de données 4D historique.',
    solution: 'OnlineCreation a apporté la puissance de Wordpress, à laquelle nous avons intégré les applications et formulaires historiques 4D. En outre, des outils faciles d\'utilisation de type <i>no-code</i> ont été déployés pour rendre le site encore plus flexible et le client plus autonome.<br>Une véritable relation de confiance s\'est nouée, avec le développement et la maintenance de plusieurs sites web, dont celui de la <a href="https://www.semaineducerveau.fr" target="_blank" rel="noreferrer">Semaine du Cerveau</a>, l\'évènement national de vulgarisation scientifique.',
    technologies: ['wordpress', 'bootstrap', 'php', 'apache', 'debianlinux'],
    year: 2016,
    category: 'site',
  },
  {
    id: 4,
    title: 'Cédric Farrusseng',
    logo: require('./assets/farrusseng-logo.png'),
    image: require('./assets/farrusseng-web.jpg'),
    url: 'https://www.cedric-farrusseng.fr',
    brief: 'Site web d\'un artisan spécialiste de l\'aménagement en pierre',
    need: 'Équipé d\'un site vieillissant, Cédric Farrusseng peinait à transmettre véritablement l\'esprit de son métier : travailler des pierres nobles pour créer du mobilier sublimes.',
    solution: 'OnlineCreation a réimaginé les standards du web pour donner un maximum de valeur aux travaux de Cédric Farrusseng, au point de faire oublier au visiteur qu\'il est sur un site web. Tout a été pensé pour rendre la technologie efficace au point de disparaitre : photos en plein écran, cadrage adaptatif à tout type d\'écran, encadrement minimaliste pour laisser un maximum de place aux travaux présentés…',
    technologies: ['wordpress', 'graphicdesign', 'html5', 'js'],
    year: 2023,
    category: 'site',
  },
  {
    id: 5,
    title: 'BRIC (Bordeaux Institute of Oncology)',
    logo: require('./assets/bric-logo.png'),
    image: require('./assets/bric-web.jpg'),
    url: 'https://www.bricbordeaux.com',
    brief: 'Site web de l\'Institut de recherche en oncologie de Bordeaux',
    need: 'Toutes les équipes de recherche en oncologie de l\'Université de Bordeaux s\'unissent pour créer un institut unique, le BRIC. Sa première mission&nbsp;: exposer la force de frappe de cette union sur une plateforme web.',
    solution: 'OnlineCreation a imaginé un site web bilingue mettant en avant les 11 équipes de recherches disposant des dernières innovations en imagerie scientifique. Les équipes administratives ont été accompagnées vers une autonomie maximale dans la gestion du site. <br>Un outil a été développé pour permettre aux chefs d\'équipes de rédiger eux-même les pages qui leur sont dédiées, présentant leurs dernières publications scientifiques (directement extraites de PubMed), les axes de recherche du moment et leur équipe.',
    technologies: ['wordpress', 'php', 'graphicdesign', 'html5', 'js'],
    year: 2022,
    category: 'site',
  },
  {
    id: 6,
    title: 'Consult & Care Office',
    logo: require('./assets/consultcare-logo.png'),
    image: require('./assets/consultcare-web.jpg'),
    url: 'https://www.consultcareoffice.com',
    brief: 'Site web d\'un cabinet de conseil en patrimoine',
    need: 'Ce cabinet de conseil en patrimoine naissant a eu l\'excellent réflexe de contacter OnlineCreation pour exister sur le web au plus tôt, pour un budget très serré, sans faire de sacrifice sur l\'esthétique ou le fonctionnel.',
    solution: 'En nous appuyant sur la technologie d\'OnlineCreation.me, nous avons pu dessiner et réaliser un site vitrine 1-page en un temps record. Avec un design sobre et efficace et grâce à la puissance d\'OnlineCreation.me, le coût a été compressé à l\'extrême. Le client a pu ainsi se concentrer sur son activité et la création de sa base clients.',
    technologies: ['html5', 'js', 'bootstrap', 'onlc'],
    year: 2021,
    category: 'site',
  },
  {
    id: 7,
    title: 'Katakana.Rocks',
    logo: require('./assets/katakana-logo.png'),
    image: require('./assets/katakana-web.jpg'),
    url: 'https://www.katakana.rocks',
    brief: 'Application d\'apprentissage du japonais',
    need: 'Le Japon est une destination de rêve. Pour profiter au mieux d\'une visite, il est indispensable de pouvoir déchiffrer quelques caractères japonais. Problème&nbsp;: une bonne maîtrise est impossible pour un étranger sans y consacrer plusieurs années.',
    solution: 'Nous avons développé une solution capable de maximiser la courbe d\'apprentissage en se focalisant sur l\'aspect le plus efficace&nbsp;: le syllabaire des Katakana, utilisés pour transcrire les mots étrangers.<br>En seulement deux heures, vous maîtriserez sans effort 20% du vocabulaire japonais&nbsp;!',
    technologies: ['js', 'vuejs', 'ios', 'android', 'graphicdesign'],
    year: 2021,
    category: 'app',
  },
  {
    id: 8,
    title: 'OnlineCreation.me',
    logo: require('./assets/onlc-logo.png'),
    image: require('./assets/onlc-web.jpg'),
    url: 'https://landing.onlinecreation.me',
    brief: 'Plateforme de création de site web',
    need: 'En 2006, pour concevoir un site web, il fallait soit être un expert en développement, soit être prêt à payer une petite fortune à une agence web. Toutes les solutions existantes présentaient des barrières financières ou techniques.',
    solution: 'Nous avons conçu OnlineCreation.me pour éliminer un maximum de barrières dans la création de site web. La barrière technique a été supprimée, le webmaster saisit ses contenus aussi simplement que l\'on écrit un e-mail. La barrière financière a été supprimée, avec une tarification commençant à 15 € par an pour un site vitrine, hébergement compris, grâce à une automatisation maximale.',
    technologies: ['js', 'php', 'graphicdesign', 'mysql', 'debianlinux'],
    year: 2006,
    category: 'site',
  },
  {
    id: 9,
    title: 'Z',
    logo: require('./assets/z-logo.png'),
    image: require('./assets/z-web.jpeg'),
    url: 'https://z.onlinecreation.pro',
    brief: 'Jeu de réflexion inspiré de 2048',
    need: 'En 2014, tout le monde jouait à <a href="https://play2048.co/" target="_blank" rel="noreferer">2048</a>. Le problème, c\'est qu\'après quelques parties, le jeu devient bien trop simple !',
    solution: 'Nous avons conçu Z pour apporter un peu de piment à ce jeu de réflexion.<br/>Plutôt que d\'assembler des chiffres, vous assemblez des lettres. Assemblez deux A pour obtenir un B, deux C pour obtenir un D, etc. Grâce à une grille de 25 cases, il est théoriquement probable d\'obtenir la lettre Z, l\'objectif final du jeu.<br>Le jeu est plus difficile, mais aussi terriblement addictif&nbsp;! Personne n\'est arrivé jusqu\'au Z à ce jour. Seriez-vous le premier&nbsp;? N\'oubliez pas de nous le faire savoir&nbsp;!',
    technologies: ['html5', 'js', 'css3'],
    year: 2018,
    category: 'game',
  },
  /*
  {
    id: 9,
    title: 'Astrohamster',
    logo: require('./assets/astrohamster-logo.png'),
    image: require('./assets/astrohamster-web.jpg'),
    url: 'https://www.astrohamster.com',
    brief: 'Un hamster dans l\'espace',
    need: 'Parfois, un développeur a simplement besoin de développer quelque chose d\'absolument inutile et drôle.',
    solution: 'Astrohamster, le rétro hamster pirate de l\'espace, traverse la galaxie dans sa fusée-poubelle en écoutant la musique tellement fort, que le son traverse le vide intersidéral et atteint la Terre. Partagez avec lui quelques minutes de voyage à la vitesse de 1&nbsp;000 années-lumières à la seconde et réussissez l\'exploit de ne pas devenir fou à l\'écoute de sa musique&nbsp;! N\'oubliez surtout pas de faire découvrir ce site à vos amis, qui se prête parfaitement au jeu du faux lien. 😉',
    technologies: ['html5', 'graphicdesign'],
    year: 2013,
    category: 'game',
  },
  */
  /* {
    id: 9,
    title: 'Pixel•OnlineCreation',
    logo: require('./assets/pixel-onlinecreation-logo.png'),
    image: require('./assets/pixel-onlinecreation-web.jpeg'),
    url: 'https://pixel.onlinecreation.me',
    brief: 'Éditeur d\'images en ligne',
    need: 'Créer des visuels est indispensable pour exister sur le web, poster sur les réseaux sociaux ou illustrer un site web. Le problème, c\'est qu\'il existe trois catégories d\'outils de création graphiques&nbsp;: ceux qui sont trop compliqués, ceux qui sont trop chers et ceux qui sont les deux à la fois.',
    solution: 'Pixel•OnlineCreation est un éditeur d\'images en ligne qui permet de créer des images de qualité professionnelle sans avoir à installer de logiciel, avec une facilité maximale. Choisissez le format de votre image (publication Instagram, bandeau Twitter, etc.), ajoutez du texte, des images, des formes, des effets, etc. et téléchargez votre image en un clic. Nul besoin d\'être un grand artiste pour combler vos besoins graphiques du quotidien&nbsp;!',
    technologies: ['html5', 'js', 'css3', 'graphicdesign'],
    year: 2018,
    category: 'site',
  }, */
  {
    id: 10,
    title: 'Punchi.me',
    logo: require('./assets/punchi-logo.png'),
    image: require('./assets/punchi-web.jpeg'),
    url: 'https://punchi.me',
    brief: 'Pour mettre KO la taille de vos images',
    need: 'La plupart des appareils photos et téléphones génèrent des images bien trop lourdes pour être publiées sur le web. Il faut donc les réduire en taille, mais cela peut prendre du temps et être fastidieux.',
    solution: 'Pour les tâches simples, oubliez les éditeurs d\'images lourds qui mettent dix minutes à démarrer. Punchi.me est un outil en ligne qui permet de réduire la taille de vos images en un clic. Il suffit de sélectionner l\'image à réduire, l\'application fait le reste&nbsp;: redimensionnement à une largeur de 1920 pixels, conversion en JPEG si nécessaire et téléchargement de l\'image optimisée sans autre intervention de votre part. Le résultat est amplement suffisant pour être publié sur le web et affichée en plein écran sans perte de qualité, mais pèse très largement moins lourd. Simple et d\'une rapidité exceptionnelle&nbsp;! Punchi.me est disponible en français, anglais et japonais.',
    technologies: ['html5', 'js', 'vuejs', 'graphicdesign'],
    year: 2018,
    category: 'site',
  },
  {
    id: 11,
    title: 'Logo•OnlineCreation',
    logo: require('./assets/logo-onlinecreation-logo.png'),
    image: require('./assets/logo-onlinecreation-web.jpeg'),
    url: 'https://logo.onlinecreation.me',
    brief: 'Créateur de logos en ligne',
    need: 'Avoir une identité visuelle est strictement indispensable à tout projet numérique, mais qui a les moyens de payer un graphiste pour créer un logo&nbsp;?',
    solution: 'Logo•OnlineCreation est un créateur de logos en ligne qui permet de créer des logos de qualité professionnelle sans avoir à installer de logiciel, avec une facilité maximale. Choisissez un pictogramme, ajoutez du texte, sélectionnez une police de caractère, des couleurs et téléchargez votre logo. Cela ne vous a pris que quelques minutes et vous a fait économiser plusieurs centaines d\'euros.',
    technologies: ['html5', 'js', 'css3', 'vuejs', 'graphicdesign'],
    year: 2018,
    category: 'site',
  },
  {
    id: 12,
    title: 'Bordeaux Imaging Center',
    logo: require('./assets/bic-logo.png'),
    image: require('./assets/bic-web.jpg'),
    url: 'https://www.bic.u-bordeaux.fr',
    brief: 'Vitrine de la plateforme d\'imagerie de Bordeaux',
    need: 'L\'Université de Bordeaux est une très grande université, qui offre une large palette de services aux étudiants, aux chercheurs et aux entreprises. Le Bordeaux Imaging Center est l\'un services d\'imagerie et de microscopie, exceptionnel par son niveau technologique. Créer un site web pour présenter ce service était indispensable.',
    solution: 'OnlineCreation a été choisie pour initier un petit site web d\'une page, puis pour accompagner les équipes à son évolution et à sa maintenance. Le site est aujourd\'hui un outil de communication indispensable pour le Bordeaux Imaging Center.',
    technologies: ['wordpress', 'graphicdesign', 'html5', 'js'],
    year: 2017,
    category: 'site',
  },
  {
    id: 13,
    title: 'L\'usine à QRCode',
    logo: require('./assets/qrcode-logo.png'),
    image: require('./assets/qrcode-web.jpg'),
    url: 'https://qrcode.onlinecreation.pro/',
    brief: 'Générateur de codes à prendre en photo',
    need: 'Faire le lien entre le monde physique et le monde numérique est un enjeu majeur. Le QRCode est un moyen simple et efficace de téléporter un prospect vers une ressources sur le web, mais sa génération peut parfois être compliquée.',
    solution: 'L\'usine à QRCode est un générateur de QRCode en ligne qui permet de créer des codes à prendre en photo avec un smartphone. Il suffit de saisir l\'adresse de la page web sur laquelle l\'utilisateur  doit arriver et de télécharger le QRCode généré. Le code peut être personnalisé&nbsp;: style, couleur et intégration de logo. L\'usine à QRCode est disponible en français, anglais et japonais.',
    technologies: ['vuejs', 'js', 'graphicdesign'],
    year: 2017,
    category: 'site',
  },
]

class WindowPortfolio extends Component {
	constructor(props){
		super(props)
		this.state = {
      selectedSite: null,
      opacity: 0,
    }
	}
  nextSite() {
    const index = portfolio.indexOf(this.state.selectedSite)
    let nextSite = null
    if (index >= portfolio.length - 1) {
      nextSite = portfolio[0]
    } else {
      nextSite = portfolio[index + 1]
    }
    this.changeSite(true)
    setTimeout(() => {
      this.changeSite(nextSite)
    }, 100)
  }
  previousSite() {
    const index = portfolio.indexOf(this.state.selectedSite)
    let previousSite = null
    if (index < 1) {
      previousSite = portfolio[portfolio.length - 1]
    } else {
      previousSite = portfolio[index - 1]
    }
    this.changeSite(true)
    setTimeout(() => {
      this.changeSite(previousSite)
    }, 10)
  }
  siteClick(site) {
    const that = this
    return () => {
      that.changeSite(site).bind(that)
    }
  }
  componentDidMount () {
    this.props.centerWindows();
    setTimeout(() => {
      this.props.centerWindows();
      this.setState(() => ({opacity: 1}))
    }, 150)
  }
  componentDidUpdate () {
    this.props.centerWindows()
  }
  changeSite(site){
    window.scrollTo(0, 0)
    this.setState(() => ({
      selectedSite: site
    }))
  }
  close() {
    this.props.close()
  }
	render(){
    return (
      <>
        { !this.state.selectedSite && <Draggable bounds="parent" handle=".draggable-title">
          <Window style={{opacity: this.state.opacity}} className='portfolio-window window center-window'>
            <WindowHeader className='window-title'>
              <div className="float-right">
                <Button onClick={this.close.bind(this)}>
                  &times;
                </Button>
              </div>

              <div className="draggable-title">
                <span className="Websrch_16x16_4 window-icon "></span> <span> realisations.exe</span>
              </div>
            </WindowHeader>
            <WindowContent>
              <div className="overflow-table">
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableHeadCell disabled>Client</TableHeadCell>
                      <TableHeadCell disabled>Projet</TableHeadCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {portfolio.map((site, index) => (
                      <TableRow key={index} onClick={this.changeSite.bind(this, site)}>
                        <TableDataCell style={{ textAlign: 'center', verticalAlign: 'bottom' }}>
                          <img src={site.logo} alt={site.title} height="48" className="all-8 img-logo"/>
                        </TableDataCell>
                        <TableDataCell><strong>{site.title}</strong><br />{site.brief}</TableDataCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </div>
              <p className='align-right top-16'>
                <Button size='lg' className="buttonLg" onClick={this.close.bind(this)}>Fermer</Button>&nbsp;
                <Button size='lg' className="buttonLg" onClick={this.props.openContact.bind(this)} primary>Contacter OnlineCreation</Button>
              </p>
            </WindowContent>
          </Window>
        </Draggable>}
        {this.state.selectedSite && this.state.selectedSite !== true && <WindowSite site={this.state.selectedSite} centerWindows={this.props.centerWindows.bind(this)}  selfChangeSite={this.changeSite.bind(this)} openContact={this.props.openContact.bind(this)} nextSite={this.nextSite.bind(this)} previousSite={this.previousSite.bind(this)} /> }
      </>
    )
  }
}
export default WindowPortfolio
