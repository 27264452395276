import React, {Component} from "react"

import {
  Tooltip
} from 'react95'

class Icons extends Component {

  icons = [
    {
      id: 'wordpress',
      name: 'Wordpress',
      icon: 'fa-brands fa-wordpress',
    },
    {
      id: 'php',
      name: 'PHP',
      icon: 'fa-brands fa-php',
    },
    {
      id: 'vuejs',
      name: 'VueJS',
      icon: 'fa-brands fa-vuejs',
    },
    {
      id: 'html5',
      name: 'HTML5',
      icon: 'fa-brands fa-html5',
    },
    {
      id: 'js',
      name: 'Javascript',
      icon: 'fa-brands fa-js',
    },
    {
      id: 'css3',
      name: 'CSS3',
      icon: 'fa-brands fa-css3-alt',
    },
    {
      id: 'bootstrap',
      name: 'Bootstrap',
      icon: 'fa-brands fa-bootstrap',
    },
    {
      id: 'react',
      name: 'React',
      icon: 'fa-brands fa-react',
    },
    {
      id: 'apache',
      name: 'Apache',
      icon: 'fa-solid fa-feather',
    },
    {
      id: 'mysql',
      name: 'MySQL',
      icon: 'fa-solid fa-database',
    },
    {
      id: 'debianlinux', 
      name: 'Debian GNU/Linux',
      icon: 'fa-brands fa-linux',
    },
    {
      id: 'graphicdesign',
      name: 'Graphisme',
      icon: 'fa-solid fa-pen-paintbrush',
    },
    {
      id: 'onlc',
      name: 'OnlineCreation.me',
      icon: 'fak fa-onlc',
    },
    {
      id: 'ios',
      name: 'Application iOS',
      icon: 'fa-brands fa-app-store-ios',
    },
    {
      id: 'android',
      name: 'Application Android',
      icon: 'fa-brands fa-android',
    },
    {
      id: 'symfony',
      name: 'Symfony',
      icon: 'fa-brands fa-symfony',
    },
    {
      id: 'eye',
      name: 'Eye',
      icon: 'fa-sharp fa-solid fa-eye',
    },
    {
      id: 'france',
      name: 'France',
      icon: 'fak fa-france-map',
    },
    {
      id: 'chevron-left',
      name: 'Chevron Left',
      icon: 'fa-sharp fa-solid fa-chevron-left',
    },
    {
      id: 'chevron-right',
      name: 'Chevron Right',
      icon: 'fa-sharp fa-solid fa-chevron-right',
    },
    {
      id: 'onlinecreation',
      name: 'OnlineCreation.pro',
      icon: 'fak fa-onlinecreation',
    },
    {
      id: 'github',
      name: 'Github',
      icon: 'fa-brands fa-github',
    },
    {
      id: 'linkedin',
      name: 'Linkedin',
      icon: 'fa-brands fa-linkedin',
    },
    {
      id: 'facebook',
      name: 'Facebook',
      icon: 'fa-brands fa-facebook',
    },
    {
      id: 'ai',
      name: 'Intelligence artificielle',
      icon: 'fa-regular fa-microchip-ai',
    },
  ]

  getTechIcon(tech, index){
    let icon = this.icons.find(icon => icon.id === tech)
    if(icon){
      return <Tooltip text={icon.name} enterDelay={0} leaveDelay={0} key={index}>
        <span  style={{ height: '80px', minWidth: '70px', display: 'inline-block' }}><i className={icon.icon + ' fa-3x all-16'}/></span>
      </Tooltip>
    }
    return null
  }
  render(){
    return this.getTechIcon(this.props.icon, this.props.index)
  }
}
export default Icons