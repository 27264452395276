import React, {Component} from "react"

import {
  Button,
  Window,
  WindowContent,
  WindowHeader,
} from 'react95'

import WindowPortfolio from './WindowPortfolio';
import WindowAbout from './WindowAbout';
import WindowContact from './WindowContact';

import Draggable from 'react-draggable';

class WindowHome extends Component {
	constructor(props){
		super(props)
		this.state = {
      contact: false,
      about: false,
      portfolio: false,
      home: true
    }
	}

  handleClickIcon = () => {
    const icon = document.querySelector(".icon-onlinecreation");
    icon.classList.add("active");
  };

  handleDoubleClickIcon = () => {
    const icon = document.querySelector(".icon-onlinecreation");
    icon.classList.remove("active");
    this.setState(() => ({home: true}));
  }

  openContact () {
    this.closeAll()
    this.openWindow('contact')
  }
  openAbout (){
    this.closeAll()
    this.openWindow('about')
  }
  openPortfolio () {
    this.closeAll()
    this.openWindow('portfolio')
  }
  openWindow (win) {
    switch(win) {
      case 'contact':
        this.setState(() => ({contact: true}))
        break;
      case 'about':
        this.setState(() => ({about: true}))
        break;
      case 'portfolio':
        this.setState(() => ({portfolio: true}))
        break;
      default:
        console.error('This window does not exist')
    }
  }
  componentDidUpdate () {
    this.centerWindows()
  }
  closeContact () {
    this.closeAll()
  }
  closeAbout () {
    this.closeAll()
  }
  closePortfolio () {
    this.closeAll()
  }
  closeAll () {
    window.scrollTo(0, 0)
    this.setState(() => ({contact: false, about: false, portfolio: false}))
  }
  componentDidMount() {
    // if URL ends with 9-legal.html, open About
    // if URL ends with #contact, open Contact
    // if URL ends with #realisations, open Portfolio
    if (window.location.href.indexOf('#contact') > -1) {
      this.openContact()
    }
    if (window.location.href.indexOf('#realisations') > -1) {
      this.openPortfolio()
    }
    if (window.location.href.indexOf('9-legal.html') > -1) {
      this.openAbout()
    }
    window.onresize = this.centerWindows
  }
  
  centerWindows () {
    document.querySelectorAll('.window').forEach((win) => {
      if (win.offsetHeight >= window.innerHeight) {
        win.style.top = '0px'
      } else {
        win.style.top = (window.innerHeight - win.offsetHeight)/2 + 'px'
      }
    })
  }

  closeWindowHome() {
    this.setState({home: false})
  }

	render(){
    return (
      <>
        <div className="icon-container">
          <a className={`icon-onlinecreation ${this.state.home ? 'hidden' : ''}`} href="#" onClick={this.handleClickIcon.bind(this)} onDoubleClick={this.handleDoubleClickIcon.bind(this)}>
            <span>OnlineCreation.exe</span>
          </a>
        </div>
          <div className={`windows-container window-home ${this.state.home ? '' : 'hidden'}`}>
            {!this.state.contact && !this.state.about && !this.state.portfolio && <Draggable bounds="parent" handle=".draggable-title">
              <Window className='main-window window center-window'>
                <WindowHeader className='window-title'>
                  <div className="float-right">
                    <Button onClick={this.closeWindowHome.bind(this)}>
                      &times;
                    </Button>
                  </div>

                  <div className="draggable-title">
                    <i className="fak fa-onlinecreation"></i><span> OnlineCreation.exe</span>
                  </div>
                </WindowHeader>
                <WindowContent>
                  <div className="all-16">
                    <div className="User5_32x32_4 transform-2x right-16 inline-block vertical-middle"></div> 
                    <span className="text-lg">
                      Nous <strong>&lt;codons&gt;</strong> pour <strong>&lt;vous&gt;</strong>
                    </span>
                  </div>
                  &nbsp;
                  <p className='align-right'>
                    <Button size='lg' className="buttonLg" onClick={this.openContact.bind(this)}>Contacter</Button>
                    <Button size='lg' className="buttonLg" onClick={this.openAbout.bind(this)}>À propos</Button>
                    <Button size='lg' className="buttonLg" primary onClick={this.openPortfolio.bind(this)}>Voir le portfolio</Button>
                  </p>
                </WindowContent>
              </Window>
            </Draggable>}
            {this.state.contact && <WindowContact close={this.closeContact.bind(this)} openContact={this.openContact.bind(this)} centerWindows={this.centerWindows.bind(this)} />}
            {this.state.about && <WindowAbout close={this.closeAbout.bind(this)} openContact={this.openContact.bind(this)} centerWindows={this.centerWindows.bind(this)} />}
            {this.state.portfolio && <WindowPortfolio close={this.closePortfolio.bind(this)} openContact={this.openContact.bind(this)} centerWindows={this.centerWindows.bind(this)} />}
          </div>
      </>
    )
  }
}
export default WindowHome
