import React, {Component} from "react"

import {
  Button,
  GroupBox,
  Window,
  WindowContent,
  WindowHeader
} from 'react95'

import Draggable from 'react-draggable';

import Icons from './Icons'

class WindowSite extends Component {
	constructor(props){
		super(props)
		this.state = {
      site: props.site
    }
	}

  close() {
    this.props.selfChangeSite(null)
  }
  nextSite() {
    this.props.nextSite()
  }
  previousSite() {
    this.props.previousSite()
  }
  openSite() {
    window.open(this.state.site.url, '_blank')
  }
  componentDidUpdate () {
    let i = setInterval(this.props.centerWindows.bind(this), 1)
    setTimeout(() => {
      clearInterval(i)
    }, 1000)
  }
  componentDidMount() {
    let i = setInterval(this.props.centerWindows.bind(this), 1)
    setTimeout(() => {
      clearInterval(i)
    }, 1000)
  }
	render(){
    if (this.state.site === null) return null
    return (
      <Draggable bounds="parent" handle=".draggable-title">
        <Window className='portfolio-site-window window center-window'>
          <WindowHeader className='window-title'>
            <div className="float-right">
              <Button onClick={this.close.bind(this)}>
                &times;
              </Button>
            </div>

            <div className="draggable-title">
              <span className="WebLink_16x16_4 window-icon"></span><span> {this.state.site.title}</span>
            </div>
          </WindowHeader>
          <WindowContent>
            <div className="clearfix"></div>
            <div className="flex-horizontal">
              <Button onClick={this.previousSite.bind(this)} className="self-center nextPreviousButton">
                <i className="fa-sharp fa-solid fa-chevron-left"></i>
              </Button>
              <GroupBox label='Aperçu' className="flex-1 all-16">
                <img src={this.state.site.image} alt={this.state.site.title} className="inner-border pointer sitepreview" onClick={this.openSite.bind(this)}/>
                <div className="top-8 align-center">
                  <Button onClick={this.openSite.bind(this)}><i className="fa-sharp fa-solid fa-eye right-8"></i> Visiter le site</Button>
                </div>
              </GroupBox>
              <div className="flex-1">
                <GroupBox label='Besoin' className="all-16">
                  <p dangerouslySetInnerHTML={{ __html: this.state.site.need}} />
                </GroupBox>
                <GroupBox label='Solution apportée par OnlineCreation' className="all-16">
                  <p dangerouslySetInnerHTML={{ __html: this.state.site.solution}} />
                </GroupBox>
                <GroupBox label='Technologies' className="all-16">
                  <div className="tech">
                    {this.state.site.technologies.map((tech, index) => <Icons icon={tech} index={index} />)}
                  </div>
                </GroupBox>
              </div>
              <Button onClick={this.nextSite.bind(this)} className="self-center nextPreviousButton">
                <i className="fa-sharp fa-solid fa-chevron-right"></i>
              </Button>
            </div>
            <p className='align-right top-16'>
              <Button size='lg' className="buttonLg" onClick={this.close.bind(this)}>Fermer</Button>&nbsp;
              <Button size='lg' className="buttonLg" onClick={this.props.openContact.bind(this)} primary>Contacter OnlineCreation</Button>
            </p>
          </WindowContent>
        </Window>
      </Draggable>
    )
  }
}
export default WindowSite; 
