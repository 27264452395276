import React, {Component} from "react"

import {
  Window,
  WindowHeader,
  WindowContent,
} from 'react95'

import Icons from "./Icons";

class SplashScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: true
    };
  }
  componentDidMount() {
    setTimeout(() => {
        this.setState(() => ({visible: false}))
    }, 2000)
    this.centerWindows()
    window.onresize = this.centerWindows
  }
  centerWindows () {
    document.querySelectorAll('.window').forEach((win) => {
      if (win.offsetHeight >= window.innerHeight) {
        win.style.top = '0px'
      } else {
        win.style.top = (window.innerHeight - win.offsetHeight)/2 + 'px'
      }
    })
  }
    
  render() {
    const allTechologies = ['react', 'mysql', 'apache', 'symfony', 'onlinecreation', 'wordpress', 'bootstrap', 'html5', 'js', 'css3', 'vuejs', 'graphicdesign', 'php', 'onlc', 'ios', 'android', 'debianlinux', 'eye', 'france', 'chevron-left', 'chevron-right', 'github', 'linkedin', 'facebook']
    return (
      this.state.visible &&
      <div className="splash-screen windows-container">
        <Window className='window center-window splash-window'>
            <WindowHeader className='window-title' style={{ fontWeight: 'normal', borderWidth: '1px 1px 0 1px', }}>
                Veuillez patienter…
            </WindowHeader>
            <WindowContent style={{ padding: '0 1px 0 1px', margin: '0'}}>
                <div style={{ backgroundColor: '#fff', padding: '16px 0 16px 0'}}>
                    <div className="blue-text" style={{width: '235px', margin: 'auto', height: '96px'}}>
                        <div className="float-left">
                            <i className="fak fa-onlinecreation fa-6x"></i>
                        </div> 
                        <div className="logo">
                            <span>Online</span>
                            <span>Creation</span>
                        </div>
                        <div style={{clear: 'both'}}></div>
                    </div>
                </div>
                <div className="gradient-loader"></div>
                <div className="bottom-16" style={{height: '1px', overflow: 'hidden'}}>
                    {allTechologies.map((tech, i) => {
                        return <Icons key={i} icon={tech} />
                    })}
                    <img src="https://static.onlc.eu/onlinecreationNDD/optimised/152736583435.png" alt="Basé à Bordeaux"></img>
                    <span className="WebLink_16x16_4 window-icon"></span>
                    <span className="Websrch_16x16_4 window-icon "></span>
                    <div className="User5_32x32_4 transform-2x right-16 inline-block vertical-middle"></div>
                    <div className="Awfext326051_32x32_4 transform-2x right-16 inline-block vertical-middle"></div>
                    <span className="Mail_16x16_4 window-icon "></span>
                    <div className="User2_32x32_4 transform-2x right-16 inline-block vertical-middle"></div>
                    <span className="Help_16x16_4 window-icon "></span>
                </div>
                <p className="all-32">
                    Création du profil utilisateur…
                </p>
            </WindowContent>
        </Window>
        </div>
    )
  }
}

export default SplashScreen