import React, {Component} from "react"

import { 
  Button,
  GroupBox,
  Window,
  WindowContent,
  TextInput,
  WindowHeader,
  ProgressBar
} from 'react95'

import Draggable from 'react-draggable';

import md5 from 'js-md5'

class WindowContact extends Component {
	constructor(props){
		super(props)
		this.state = {
      messageSent: false,
      loading: false,
      closing: false,
      percent: 0,
      timer : null,
      mountTime: null,
      opacity: 0,
      fieldsMicroseconds: {
        subject: 0,
        email: 0,
        message: 0
      },
      fieldsTime: {
        subject: 0,
        email: 0,
        message: 0
      },
    }
	}
  dialogConfirm() {
    window.scrollTo(0, 0)
    this.setState(() => ({closing: true}))
  }
  closeDialogConfirm() {
    this.setState(() => ({closing: false}))
  }
  close() {
    this.props.close()
  }
  componentDidMount() {
    this.setState(() => ({mountTime: new Date().getTime()}))
    this.props.centerWindows();
    setTimeout(() => {
      this.props.centerWindows();
      this.setState(() => ({opacity: 1}))
    }, 150)
  }
  componentWillUnmount() {
    this.setState(() => ({mountTime: 0}))
  }
  fakeProgress() {
    this.timer = setInterval(() => {
      if (this.state.percent < 25) {
        this.setState(() => ({
          percent: this.state.percent + 1
        }))
      }
      if (this.state.percent >= 25 && this.state.percent < 50) {
        this.setState(() => ({
          percent: this.state.percent + 0.5
        }))
      }
      if (this.state.percent >= 50 && this.state.percent < 75) {
        this.setState(() => ({
          percent: this.state.percent + 0.25
        }))
      }
      if (this.state.percent >= 75 && this.state.percent < 90) {
        this.setState(() => ({
          percent: this.state.percent + 0.1
        }))
      }
      if (this.state.percent >= 90 && this.state.percent < 100) {
        this.setState(() => ({
          percent: this.state.percent + 0.01
        }))
      }
      if (this.state.percent >= 100) {
        this.setState(() => ({
          percent: 0
        }))
      }
    }, 100)
  }
  fieldFocus(field) {
    let fieldsTime = this.state.fieldsTime
    fieldsTime[field] = new Date().getTime()
    this.setState(() => ({
      fieldsTime: fieldsTime
    }))
  }      
  fieldBlur(field) {
    let fieldsMicroseconds = this.state.fieldsMicroseconds
    let fieldsTime = this.state.fieldsTime
    fieldsMicroseconds[field] = new Date().getTime() - fieldsTime[field]
    this.setState(() => ({
      fieldsMicroseconds: fieldsMicroseconds
    }))
  }
  subjectFocus() {
    this.fieldFocus('subject')
  }
  subjectBlur() {
    this.fieldBlur('subject')
  }
  emailFocus() {
    this.fieldFocus('email')
  }
  emailBlur() {
    this.fieldBlur('email')
  }
  messageFocus() {
    this.fieldFocus('message')
  }
  messageBlur() {
    this.fieldBlur('message')
  }
  disabledField() {
    if (this.state.loading) {
      return true
    }
    return false
  }
  componentDidUpdate () {
    this.props.centerWindows()
  }
  sendMessage() {
    if (document.getElementById('email').value.length < '6' || document.getElementById('email').value.indexOf('@') === -1 || document.getElementById('email').value.indexOf('.') === -1) {
      alert('Veuillez saisir votre adresse e-mail.')
      return
    }
    if (document.getElementById('message').value.length < 10) {
      alert('Veuillez saisir votre message (10 caractères minimum)')
      return
    }
    if (document.getElementById('subject').value === '') {
      alert('Veuillez saisir un sujet.')
      return
    }
    this.setState(() => ({
      messageSent: false,
      closing: false,
      loading: true,
      percent: 0  
    }))
    const sendTime = new Date().getTime()
    const data = {
      name: document.getElementById('name').value,
      email: document.getElementById('email').value,
      subject: document.getElementById('subject').value,
      message: document.getElementById('message').value,
      phone: document.getElementById('phone').value,
      fieldsMicroseconds: JSON.stringify(this.state.fieldsMicroseconds),
      mountTime: this.state.mountTime,
      sendTime: sendTime,
      userAgent: navigator.userAgent,
      formTime: sendTime - this.state.mountTime,
      checksum: md5(document.getElementById('email').value + document.getElementById('message').value + JSON.stringify(this.state.fieldsMicroseconds))
    }
    const xhr = new XMLHttpRequest()
    xhr.open('POST', 'send.php', true)
    xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded')
    xhr.onreadystatechange = () => {
      if (xhr.readyState === 4 && xhr.status === 200) {
        this.setState(() => ({
          loading: false,
          messageSent: true
        }))
      } else if (xhr.readyState === 4 && xhr.status !== 200) {
        alert('Une erreur est survenue, veuillez réessayer.')
        this.setState(() => ({
          loading: false,
          messageSent: false
        }))
      }
    }
    xhr.send(encodeURI('data=' + JSON.stringify(data)))
    
  }
	render(){
    return (
      <>
        <Draggable bounds="parent" handle=".draggable-title">
          <Window style={{opacity: this.state.opacity}}className={'window center-window ' + (this.state.messageSent ? 'dialog-window' : 'contact-window')}>
            <WindowHeader className='window-title'>
              <div className="float-right">
                {this.state.messageSent ? 
                  <Button onClick={this.close.bind(this)}>
                    &times;
                  </Button> : (
                  <Button onClick={this.dialogConfirm.bind(this)}>
                    &times;
                  </Button>
                )}
              </div>
              <div className="draggable-title">
                <span className="Mail_16x16_4 window-icon "></span> <span> contact2000.exe</span>
              </div>
            </WindowHeader>
            <WindowContent>
              {this.state.messageSent ? (
                <div className="top-16 left-16 right-16">
                  <div>
                    <div className="User5_32x32_4 transform-2x right-16 inline-block vertical-middle"></div>
                    Message envoyé, merci.
                  </div>
                  &nbsp;
                  <p className='align-right top-16'>
                    <Button className="buttonLg" primary size='lg' onClick={this.close.bind(this)}>&nbsp;&nbsp;&nbsp;OK&nbsp;&nbsp;&nbsp;</Button>
                  </p>
                </div>
                  ) : (
                    <>
                      <div className="all-16">
                        <div className="Awfext326051_32x32_4 transform-2x right-16 inline-block vertical-middle"></div> 
                        Parlez-nous de votre projet !
                      </div>
                      &nbsp;
                      <GroupBox label='Formulaire de contact'>
                        <div className="flex bottom-16">
                          <span className="field-label">Nom</span>
                          <TextInput
                            placeholder='Pierre Brochant'
                            fullWidth
                            name="name"
                            id="name"
                            disabled={this.disabledField()}
                          />
                        </div>
                        <div className="flex bottom-16">
                          <span className="field-label">E-mail *</span>
                          <TextInput
                            placeholder='pierre.brochant@club-internet.fr'
                            type="email"
                            fullWidth
                            required
                            name="email"
                            id="email"
                            onFocus={this.emailFocus.bind(this)}
                            onBlur={this.emailBlur.bind(this)}
                            disabled={this.disabledField()}
                          />
                        </div>
                        <div className="flex bottom-16">
                          <span className="field-label">Tel.</span>
                          <TextInput
                            placeholder='+33 5 35 54 05 04'
                            type="tel"
                            fullWidth
                            name="phone"
                            id="phone"
                            disabled={this.disabledField()}
                          />
                        </div>
                        <div className="flex bottom-16">
                          <span className="field-label">Objet *</span>
                          <TextInput
                            placeholder='Développement web'
                            fullWidth
                            required
                            name="subject"
                            id="subject"
                            onFocus={this.subjectFocus.bind(this)}
                            onBlur={this.subjectBlur.bind(this)}
                            disabled={this.disabledField()}
                          />
                        </div>
                        <TextInput
                          multiline
                          rows={4}
                          defaultValue='Bonjour,'
                          className="fill-height"
                          fullWidth
                          required
                          name="message"
                          id="message"
                          onFocus={this.messageFocus.bind(this)}
                          onBlur={this.messageBlur.bind(this)}
                          disabled={this.disabledField()}
                        />
                        <p className="top-16">* Champs obligatoires</p>
                      </GroupBox>
                      { this.state.loading ? (
                        <div className="top-16">
                          <ProgressBar variant='tile' value={Math.floor(this.state.percent)} />
                          </div>
                      ) : (
                        <p className='align-right top-16'>
                          <Button size='lg' className="buttonLg" onClick={this.dialogConfirm.bind(this)}>Annuler</Button>&nbsp;
                          <Button size='lg' className="buttonLg" primary onClick={this.sendMessage.bind(this)}>Envoyer</Button>
                        </p>
                      )}
                    </>
                  )}
            </WindowContent>
          </Window>
        </Draggable>
        {this.state.closing && (

          <Draggable bounds="parent" handle=".window-title">
            <Window className='dialog-window window center-window'>
              <WindowHeader className='window-title'>
                <div className="float-right">
                  <Button onClick={this.closeDialogConfirm.bind(this)}>
                    &times;
                  </Button>
                </div>
                <span className="Mail_16x16_4 window-icon "></span> <span>contact2000.exe</span>
              </WindowHeader>
              <WindowContent>
                <div className="top-16 left-16 right-16">
                  <div>
                    <div className="User2_32x32_4 transform-2x right-16 inline-block vertical-middle"></div>
                    Voulez-vous vraiment quitter ?
                  </div>
                  &nbsp;
                  <p className='align-right top-16'>
                    <Button className="buttonLg" size='lg' onClick={this.close.bind(this)}>Quitter sans envoyer</Button>&nbsp;
                    <Button className="buttonLg" primary size='lg' onClick={this.closeDialogConfirm.bind(this)}>Ne pas quitter</Button>
                  </p>
                </div>
              </WindowContent>
            </Window>
          </Draggable>
        )}
      </>
    );
  }
}
export default WindowContact
