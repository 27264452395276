import { useCallback } from "react";
import {styleReset} from 'react95';
import Particles from "react-tsparticles";
import { loadFull } from "tsparticles";
import { createGlobalStyle, ThemeProvider } from 'styled-components';

/* Pick a theme of your choice */
import original from 'react95/dist/themes/millenium';

/* Original Windows95 font (optional) */
import ms_sans_serif_ttf from './assets/MS-Sans-Serif.ttf';
import ms_sans_serif_svg from './assets/MS-Sans-Serif.svg';
import ms_sans_serif_woff from './assets/MS-Sans-Serif.woff';
import ms_sans_serif_woff2 from './assets/MS-Sans-Serif.woff2';
import ms_sans_serif_bold_ttf from './assets/MS-Sans-Serif-bold2.ttf';
import ms_sans_serif_bold_svg from './assets/MS-Sans-Serif-bold2.svg';
import ms_sans_serif_bold_woff from './assets/MS-Sans-Serif-bold2.woff';
import ms_sans_serif_bold_woff2 from './assets/MS-Sans-Serif-bold2.woff2';
import source_sans_pro_woff from './assets/sourcesanspro-light.woff';
import source_sans_pro_woff2 from './assets/sourcesanspro-light.woff2';
import source_sans_pro_eot from './assets/sourcesanspro-light.eot';
import source_sans_pro_ttf from './assets/sourcesanspro-light.ttf';
import source_sans_pro_svg from './assets/sourcesanspro-light.svg';

/* OnlineCreation icon (src/assets/95-app-icon.png) */
import app_icon from './assets/95-app-icon.png';

import WindowHome from './WindowHome';
import SplashScreen from './SplashScreen';

const GlobalStyles = createGlobalStyle`
  @font-face {
    font-family: 'ms_sans_serif';
    src: url('${ms_sans_serif_woff2}') format('woff2'), url('${ms_sans_serif_woff}') format('woff'), url('${ms_sans_serif_svg}') format('svg'), url('${ms_sans_serif_ttf}') format('ttf');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'ms_sans_serif';
    src: url('${ms_sans_serif_bold_woff2}') format('woff2'), url('${ms_sans_serif_bold_woff}') format('woff'), url('${ms_sans_serif_bold_svg}') format('svg'), url('${ms_sans_serif_bold_ttf}') format('ttf'), ;
    font-weight: bold;
    font-style: normal;
  }
  @font-face {
    font-family: 'Source Sans Pro';
    src: url('${source_sans_pro_eot}');
    src: url('${source_sans_pro_eot}?#iefix') format('embedded-opentype'),
      url('${source_sans_pro_woff2}') format('woff2'),
      url('${source_sans_pro_woff}') format('woff'),
      url('${source_sans_pro_ttf}') format('truetype'),
      url('${source_sans_pro_svg}#source_sans_pro_lightregular') format('svg');
    font-weight: 300;
    font-style: normal;
  }
  html {
    min-height: 100vh;
    overflow-x: hidden;
  }
  *:not(a, button, input, textarea, .pointer) {
    cursor: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAAh0lEQVRYhe2WSwrAIAxEJ6H3v3K6MaX110WbzCYPRNTFPKOIAGAgoq2nSSgAmBlNwitAk9D7gCGh/US2xCCQLTEVyJRYCmRJbAUyJI7VgogMLgCGya88KjAJla79ziXQwsUlUi+hh0eHLQW68NQqKEg7d3bh1ioQekSv7wAbA/nHVBRFURThnD/uMROGdeAFAAAAAElFTkSuQmCC"), default;
  }
  *:not(input, textarea, .selectable) {
    user-select: none;
  }
  .selectable {
    user-select: all;
  }
  html, html body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video, * {
    font-family: 'ms_sans_serif';
  }
  strong {
    font-weight: bold !important;
  }
  body {
    padding: 5rem;
    background: #0b4e7d;
  }
  table tr td[class*="sc-"] {
    padding: 5px 8px 0px 8px;
  }
  tr:hover .img-logo {
    filter: invert(1) grayscale(1) brightness(150%) ;
  }
  .window.main-window,
  .window.dialog-window {
    max-width: 500px;
    min-height: auto;
  }
  .window.splash-window {
    max-width: 600px;
    min-height: auto;
  }
  .about-window {
    max-width: 800px;
  }
  @media (min-height: 900px) {
    .about-window img {
      max-height: 200px;
    }
  }
  .overflow-table {
    position: relative;
    border-style: solid;
    border-width: 2px;
    border-left-color: #828282;
    border-top-color: #828282;
    border-right-color: #ffffff;
    border-bottom-color: #ffffff;
    box-sizing: border-box;
    padding: 2px;
  }
  .overflow-table:before {
    position: absolute;
    left: 0;
    top: 0;
    content: '';
    width: calc(100% - 4px);
    height: calc(100% - 4px);
    border-style: solid;
    border-width: 2px;
    border-left-color: black;
    border-top-color: black;
    border-right-color: #e5e5e5;
    border-bottom-color: #e5e5e5;
    pointer-events: none;
  }
  .overflow-table > div {
    overflow-y: auto;
    min-height: 200px;
    max-height: calc(100vh - 170px);
    border: none;
    padding: 0px;
  }
  .overflow-table > div:before {
    display: none;
  }
  .overflow-table table thead {
    position: sticky;
    top: 0;
  }
  .portfolio-window {
    max-width: 650px;
  }
  .portfolio-site-window {
    max-width: 1160px;
  }
  body .window{
    position: absolute;
    width: 100%;
    padding: 2px;
    border-color: #fff #7d7c78 #7d7c78 #fff;
    border-style: solid;
    box-shadow: none;
    padding: 0px;
  }
  body .window::before {
    position: absolute;
    content: '';
    border-width: 2px;
    border-color: rgb(214, 207, 199) #1f2831 #1f2831 rgb(214, 207, 199);
    border-style: solid;
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    top: -4px;
    left: -4px;
    z-index: -1;
  }
  body .window-title {
    background: linear-gradient(to right,#0A246A,#A6CAF0);
    letter-spacing: 0.75px;
    position: relative;
  }
  body .window-title .draggable-title {
    display: block;
    position: absolute;
    left: 5px;
    top: 0;
    right: 40px;
    bottom: 0;
  }
  .center-window {
  }
  .windows-container {
    position: absolute;
    display: flex;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    margin: 0;
    justify-content: safe center;
    align-items: flex-start;
    padding: 0;
  }
  .contact-window {
    max-width: 600px;
  }
  button:active{
    height: 34px;
    margin-bottom: 2px;
  }
  .top-32 {
    margin-top: 32px;
  }
  .top-16 {
    margin-top: 16px;
  }
  .top-8 {
    margin-top: 8px;
  }
  .bottom-16 {
    margin-bottom: 16px;
  }
  .right-16 {
    margin-right: 16px;
  }
  .right-8 {
    margin-right: 8px;
  }
  .left-16 {
    margin-left: 16px;
  }
  .all-32 {
    margin: 16px;
  }
  .all-16 {
    margin: 16px;
  }
  .all-8 {
    margin: 8px;
  }
  .left-80 {
    margin-left: 8px;
  }
  .align-right {
    text-align: right;
  }
  .align-center {
    text-align: center;
  }
  .float-left {
    float: left;
  }
  .float-right {
    float: right;
  }
  .window-icon {
    display: inline-block;
    margin-left: 4px;
    margin-right: 6px;
    margin-top: 8px;
    transform: scale(1.5);
  }
  .hidden {
    display: none;
  }
  .clearfix {
    clear: both;
  }
  .transform-2x {
    transform: scale(2);
    image-rendering: crisp-edges;
  }
  .transform-2x.right-16 {
    margin-right: 32px;
  }
  .inline-block {
    display: inline-block;
  }
  .vertical-middle {
    vertical-align: middle;
  }
  .field-label {
    line-height: 36px;
    margin-right: 16px;
    max-width: 33%;
    min-width: 50px;
  }
  .flex {
    display: flex;
  }
  .flex-1 {
    flex: 1;
  }
  .flex-2 {
    flex: 2;
  }
  .flex-3 {
    flex: 3;
  }
  .self-center {
    align-self: center;
  }
  .fill-height {
    height: calc(100vh - 535px);
  }
  .blue-text {
    color: #0b4e7d;
  } 
  .blue-bg {
    background: #0b4e7d;
  }
  img {
    max-width: 100%;
    object-fit: contain;
  }
  .inner-border {
    border: 2px solid #828282;
  }
  body a {
    color: #00F;
    text-decoration: underline;
    cursor: pointer;
  }
  body a * {
    cursor: pointer;
  }
  .pointer {
    cursor: pointer;
  }
  .nextPreviousButton {
    display: none;
  }
  @media(min-width: 768px) {
    .window {
      height: auto;
      min-height: auto;
    }
    .fill-height {
      height: auto;
    }
    .flex-horizontal {
      display: flex;
      flex-direction: row;
    }
    body .background-france {
      display: block;
    }
    body button.buttonLg,
    body button.buttonLg:active {
      padding: 20px 25px;
      margin-left: 16px;
      margin-top: 16px;
    }
    .nextPreviousButton {
      display: inline-block;
    }
  }
  @media (min-width: 1200px) {
    .sitepreview {
      width: 498px;
      height: 371.5px;
    }
  }
  .text-lg {
    font-size: 1.1rem;
  }
  button.buttonLg,
  button.buttonLg:active {
    padding: 20px 10px;
    margin-left: 8px;
    margin-top: 8px;
  }
  .logo {
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 2rem;
    float: left;
    padding: 1rem 0 0 0;
    margin-top: 17px;
    span {
      display: block;
      text-transform: uppercase;
      font-weight: 300;
      line-height: 1.8rem;
    }
  }
  .background-logo {
    position: fixed;
    bottom: 15px;
    color: #fff;
    left: 15px;
  }
  .background-france {
    display: none;
    position: fixed;
    bottom: 15px;
    color: #fff;
    right: 15px;
    width: 100%;
    overflow: hidden;
    .france-picto:before {
      font-family: 'Source Sans Pro', sans-serif;
      font-size: 6rem;
      content: '*';
      position: absolute;
      right: 3rem;
      top: 1rem;
      color: #999;
    }
    .france-typo {
      font-family: 'Source Sans Pro', sans-serif;
      font-size: 2rem;
      float: right;
      text-align: right;
      padding: 1rem 0 0 0;
      margin-top: 17px;
      span {
        display: block;
        text-transform: uppercase;
        font-weight: 300;
        line-height: 1.8rem;
      }
    }
  }
  .background-particles {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    overflow: hidden;
  }
  #tsparticles {
    height: 100%;
  }
  .splash-screen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    overflow: hidden;
    background: #069;
    z-index: 99999
  }
  .gradient-loader {
    background: linear-gradient(90deg, #006699, #034c70, #006699, #238fc5, #006699);
    background-size: 200% 200%;
    animation: loading 1s ease infinite;
    height: 10px;
  }
  @keyframes loading {
      0%{background-position:200% 0%}
      100%{background-position:0% 0%}
  }
  .icon-container {
    position: absolute;
    top: 30px;
    left: 30px;
    width: 130px;
  }
  .icon-onlinecreation {
    background-image: url(${app_icon});
    background-size: 64px 64px;
    background-position: top center;
    background-repeat: no-repeat;
    font-family: 'ms_sans_serif';
    text-align: center;
    padding-top: 70px;
    display: inline-block;
    color: #fff;
    text-decoration: none;
    image-rendering: crisp-edges;
    position: relative;
    width: 100%;
  }
  .icon-onlinecreation.active:before {
    background-image: url(${app_icon});
    background-size: 64px 64px;
    background-position: top center;
    background-repeat: no-repeat;
    opacity: 0.7;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    image-rendering: crisp-edges;
    filter: contrast(0) brightness(0.2) sepia(1) saturate(20) hue-rotate(215deg);
    height: 100%;
  }
  .icon-onlinecreation > span {
    padding: 0px 2px;
    border: 1px dotted transparent;
    font-weight: normal;
  }
  .icon-onlinecreation.active > span {
    background: #34406a;
    border: 1px dotted yellow;
  }
  .window-home.hidden,
  .icon-onlinecreation.hidden {
    opacity: 0;
  }
  ${styleReset}
`;

const App = () => {

  const particlesInit = useCallback(async engine => {
    await loadFull(engine);
  }, []);

  return (
    <div>
      <GlobalStyles />
      <ThemeProvider theme={original}>
        <div>
          <div className="background-particles">
            <Particles
              id="tsparticles"
              init={particlesInit}
              options={{
                backgroundMode: {
                  enable: false,
                  zIndex: 1,
                },
                fullScreen: {
                  enable: false,
                  zIndex: -1,
                },
                fpsLimit: 30,
                interactivity: {
                  detectsOn: "canvas",
                  events: {
                    onClick: {
                      enable: false,
                      mode: "push",
                    },
                    onHover: {
                      enable: false,
                      mode: "repulse",
                    },
                    resize: true,
                  },
                  modes: {
                    bubble: {
                      distance: 400,
                      duration: 2,
                      opacity: 0.4,
                      size: 40,
                    },
                    push: {
                      quantity: 4,
                    },
                    repulse: {
                      distance: 200,
                      duration: 0.4,
                    },
                  },
                },
                particles: {
                  color: {
                    value: "#ddd",
                  },
                  links: {
                    color: "#ffffff",
                    distance: 150,
                    enable: true,
                    opacity: 0.5,
                    width: 1,
                  },
                  collisions: {
                    enable: true,
                  },
                  move: {
                    direction: "none",
                    enable: true,
                    outMode: "bounce",
                    random: false,
                    speed: 0.5,
                    straight: false,
                  },
                  number: {
                    density: {
                      enable: true,
                      value_area: 800,
                    },
                    value: 30,
                  },
                  opacity: {
                    value: 0.5,
                  },
                  shape: {
                    type: "circle",
                  },
                  size: {
                    random: true,
                    value: 6,
                  },
                },
                detectRetina: true,
              }}
            />
          </div>
          {/* End particle animation */}
          <SplashScreen />
          <div className="background-logo">
            <div className="float-left">
              <i className="fak fa-onlinecreation fa-6x"></i>
            </div> 
            <div className="logo">
              <span>Online</span>
              <span>Creation</span>
            </div>
          </div>
          <div className="background-france">
            <div className="float-right france-picto">
              <i className="fak fa-france-map fa-6x"></i>
            </div>
            <div className="france-typo">
              <span>Basé à</span>
              <span>Bordeaux</span>
            </div>
          </div>
        </div>
        <WindowHome/>
      </ThemeProvider>
    </div>
  );
};

export default App;